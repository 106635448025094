.header {
    position: relative;
    width: 100%;
    max-width: 430px;
    height: 153px;
    left: 0px;
    top: 0px;

    background: linear-gradient(
        254.37deg,
        #fd755a 0%,
        #fd755a 44.37%,
        #fa2255 100%
    );
    border-radius: 0px 0px 76.5px 0px;
}

.title {
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 70px;

    letter-spacing: 0.1em;

    color: #ffffff;
}

.backButton {
    color: #fff;
    position: absolute;
    left: 20px;
    top: 20px;
    text-transform: lowercase;
    letter-spacing: 1px;
}
