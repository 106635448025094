.service_item {
    position: relative;
    /* width: 384px; */
    display: flex;
    margin: auto;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(61, 61, 61, 0.36);
    border-radius: 5px;
    padding: 15px;
    margin: 0px 15px 20px 15px;
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.05em;

    color: #000000;
}

.price_bl {
    position: absolute;
    width: 90px;
    height: 100%;
    top: 0;
    right: 0;
    background: #fd755a;
    border-radius: 0px 5px 5px 0px;
    z-index: 4;
}

.price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;

    color: #ffffff;
}

.sub_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #5e5e5e;
}

.master_item {
    margin-top: 30px;
}
