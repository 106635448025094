@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

* {
    font-family: 'Roboto';
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.client_header {
    width: 100%;
    max-width: 430px;

    margin: auto;
}
.header {
    position: relative;
    height: 150px;
    z-index: 4;
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.shape {
    position: absolute;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.79) 18.24%,
        rgba(3, 3, 3, 0.68) 28.29%,
        rgba(6, 6, 6, 0.1) 100%
    );
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.title {
    position: absolute;
    width: 280px;
    height: 26px;
    font-size: 26px;
    line-height: 26px;
    margin-left: -140px;
    text-align: center;
    top: 10px;
    left: 50%;
    right: 50%;
    color: #ffffff;
    z-index: 2;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    background: #f1f1f1;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.19); */
    /* border-radius: 0px 0px 5px 5px; */
}

.left_nav,
.right_nav {
    display: flex;
}

.nav_item {
    font-size: 18px;
    margin: 7px 10px;
}

.logo {
    position: absolute;
    left: 50%;
    margin-left: -43px;
    margin-top: -50px;
    z-index: 4;
}

.logoimg {
    width: 75px;
    height: 75px;
    border-radius: 100%;
    border: solid 3px #fff;
}
