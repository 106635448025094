.gradient {
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

button {
    border: none;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
.mainTitle {
    font-size: 50px;
}
.oppBody {
    margin-top: 70px;
}
.center_bl {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.center_bl img {
    width: 250px;
}

.info {
    margin-left: 30px;
}

.info h1 {
    text-transform: uppercase;
    font-size: 40px;
    padding-bottom: 30px;
}

.info button {
    padding: 7px 10px;
    margin-right: 15px;
    background-color: #ff5434;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 15px;
}

.info button:hover {
    cursor: pointer;
    background-color: #fa2155;
    transition: all 0.5s;
}

.info p {
    padding-top: 30px;
    font-size: 20px;
    color: #373737;
}
.logo-black .logo {
    color: #373737;
}

.navMenuText {
    color: white;
}
.navBlack .navMenuText {
    color: #373737;
}
.oppTitle {
    margin-top: 50px;
}

.oppTitleBorder {
    margin-bottom: 50px;
}
.imgOpportunitiesDesc {
    display: flex;
    justify-content: center; /* Центрируем по горизонтали */
    align-items: center; /* Центрируем по вертикали */
    width: 100px; /* Ширина блока */
    height: 100px; /* Высота блока */
    background-color: #c54683;
    border-radius: 15px;
}

.imgOpportunitiesDesc img {
    width: 40px;
    height: 40px;
}
.oppItemText {
    max-width: 320px;
}

.oppItemTitle {
    font-size: 28px;
}

.oppRight {
    margin-left: auto;
}

.h3Text {
    color: #fff;
}

.oppBtn {
    margin-top: 50px;
    margin-bottom: 50px;
}

.oppDuble {
    margin-top: 50px;
}
.iphoneMobile {
    max-width: 400px;
    margin: auto;
}
#targetOpportunitiesMobile {
    display: none;
}

.event-title,
.priceTitle {
    font-size: 50px;
}
.event-subtitle {
    font-size: 38px;
}
@media (max-width: 1020px) {
    #targetOpportunities {
        display: none;
    }
    #targetOpportunitiesMobile {
        display: block;
    }
}

@media (max-width: 614px) {
    .oppTitle,
    .event-title,
    .mainTitle {
        font-size: 34px;
    }
    .mainText {
        width: 100%;
        text-align: center;
    }
    .mainSubTitle {
        width: 100%;
        text-align: center;
    }
    .event-subtitle {
        font-size: 26px;
        padding: 0px 10px;
    }
    .oppDuble {
        flex-wrap: wrap;
        margin-top: 30px;
    }
    .oppRightBlock,
    .oppLeftBlock {
        width: 100%;
        max-width: 600px;
    }
    .oppRightBlock .item {
        text-align: center;
    }
    .oppDuble .item {
        text-align: center;
    }
    .priceBody {
        padding: 10px;
    }
    .oppRight,
    .oppLeft {
        margin: auto;
    }
    .iphoneMobileImg {
        width: 100%;
        max-width: 614px;
    }
}
