.client_container {
    width: 100%;
    max-width: 430px;
    margin: auto;
    background-color: #f1f1f1;
    min-height: 100vh;
    padding-bottom: 50px;
}

a {
    text-decoration: none;
}

.react-calendar__tile {
    flex: 0 0 13% !important;
    height: 50px;
    text-align: center;
    border: 1px solid #ece2c9;
    margin: 2px;
    background-color: #fff;
    cursor: pointer;
}

.react-calendar button {
    font-weight: 500;
    background-color: #fff;
    color: #464646;
}

.react-calendar__tile:hover {
    background-color: #f4dfdb;
}

.react-calendar__tile--active {
    background-color: #fd755a !important;
    color: #fff !important;
}

.react-calendar__navigation__label {
    font-size: 16px;
    margin: 10px 0;
    font-weight: 500;
}

.react-calendar__month-view__weekdays {
    margin: 10px 0;
}
.react-calendar__month-view__weekdays__weekday abbr {
    font-size: 20px !important;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: #464646;
    display: flex;
    justify-content: center;
}
