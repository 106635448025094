.cal {
    padding: 10px;
    font-size: 16px;
}

.cal abbr {
    font-size: 18px;
}

.times_bl {
    display: flex;
    flex-wrap: wrap;
}

.time_window {
    margin: auto;
    margin: 10px;
}
.time {
    padding: 7px 20px;
    width: 85px;
    background: #ffffff;
    border: 1px solid #ece2c9;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s;
}

.text_item {
    margin-top: 20px;
}

.text_title {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
}

.text_subtitle {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
}

.success_title {
    font-family: 'Aqum';
    font-size: 18px;
    color: #464646;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.8px;
}

.time:hover {
    background-color: #fd755a;
}

.time_active {
    background-color: #fd755a;
    color: #fff;
}

.title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin: 30px 0 20px;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 1px;
}

.success_bl {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: auto;
    width: 374px;
    margin-top: -60px;
}

.contact_centr {
    padding: 0px 20px;
    margin: auto;
    margin-bottom: 30px;
}
