/* CSS-файл для стилизации модального окна */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.navAuth {
    color: black;
}
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
}

.btnCreate {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
    background-color: #fa1d55; /* Фон по умолчанию */
}

.btnCreate::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background-color: #fd755a;
    z-index: -1;
    transition: all 1s ease-out;
    transform: translateX(-50%);
}

.btnTopActive {
    background-color: #fd755a;
}

.btnTop {
    background-color: #ef9988;
    transition: all 0.5s;
}

.btnTop:hover {
    background-color: #fd755a;
}

.btnCreate:hover::before {
    background-color: #fa1d55;
    transform: translateX(0%);
}

.btnGrey {
    background-color: #ef9988 !important;
}
.input_bl input {
    /* background-color: #000; */
}

/* Дополнительные стили, по вашему усмотрению */
