/* CitySearchComponent.css */

.city-search-container {
    max-height: 150px; /* Максимальная высота контейнера поиска */
    overflow-y: auto; /* Включаем вертикальную прокрутку при необходимости */
}

.city-search-container li {
    color: #000;
}

.city-search-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    color: black; /* Черный цвет текста */
    position: sticky; /* Зафиксировать поле ввода */
    top: 0; /* Поле ввода будет прилипать к верхней части контейнера */
    z-index: 1; /* Гарантирует, что поле ввода будет на переднем плане */
    background-color: white; /* Задаем белый фон для поля ввода */
}

.city-search-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.city-search-results li {
    padding: 8px;
    cursor: pointer;
}

.city-search-results li:hover {
    background-color: #f0f0f0; /* Изменение цвета при наведении */
}

.city-search-container {
    max-height: 300px; /* Максимальная высота контейнера поиска */
    overflow-y: auto; /* Включаем вертикальную прокрутку при необходимости */
    position: relative; /* Устанавливаем позицию относительно, чтобы было возможно зафиксировать поле ввода */
}
