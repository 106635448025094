.btn {
    display: block;
    width: 314px;
    height: 40px;
    margin: auto;
    background: linear-gradient(90deg, #fa1d55 0%, #fd755a 100%);
    border-radius: 5px;
    cursor: pointer;

    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    color: #ffffff;
}

.disabled {
    background: linear-gradient(90deg, #464545 0%, #534d4b 100%);
}

@media (max-width: 414px) {
    .btn {
        width: 284px;
    }
}
