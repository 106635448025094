.main_block {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 430px;
    margin: auto;
    background-color: #f1f1f1;
    padding-top: 0px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.online {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* margin: auto; */
    width: 100%;
    /* max-width: 374px; */
    height: 100px;
    background: #f1f1f1;
    box-shadow:
        -5px -5px 10px rgba(255, 255, 255, 0.9),
        5px 5px 13px rgba(205, 205, 205, 0.9),
        inset 1px 1px 2px rgba(255, 255, 255, 0.3),
        inset -1px -1px 2px rgba(205, 205, 205, 0.5);
    border-radius: 5px;
    /* padding: 30px; */
}

.online_btn {
    margin: auto;
    /* max-width: 314px; */
    width: 100%;
    height: 40px;

    background: linear-gradient(90deg, #fa1d55 0%, #fd755a 100%);
    border-radius: 5px;
    cursor: pointer;

    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    color: #ffffff;
}

.about {
    margin-top: 30px;
}

.subTitle_online {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    letter-spacing: 0.05em;
}

.about_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #585656;
}

.about_body {
    margin-top: 10px;

    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.04em;

    color: #333333;
}

.contact {
    margin-top: 30px;
    width: 100%;
    height: 237px;
    background: #ffffff;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
.contact_title {
    padding: 15px 0 0 15px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #585656;
}
.contact_social {
    margin-top: 15px;
    display: flex;
    border-top: 1px solid rgba(52, 52, 52, 0.2);
    border-bottom: 1px solid rgba(52, 52, 52, 0.2);
}

.social_item {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80px;
}
.social_item img {
    width: 50px;
    margin-bottom: 5px;
}

.social_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    width: 50px;
    text-align: center;

    color: #5e5e5e;
}

.location {
    margin-top: 20px;
    padding: 0 15px;
}

.location a {
    margin-bottom: 10px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #333333;
}
