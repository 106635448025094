/* Header.css */
.container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
}

.desktop-menu {
    display: flex;
}

.desktop-menu nav {
    display: flex;
}

.desktop-menu a {
    color: white;
    text-decoration: none;
    margin-right: 20px;
}

.mobile-menu {
    display: none; /* По умолчанию скрыто на десктопе */
}
.sendMessage {
    color: #bd2f1f;
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 20px;
}

/* Медиа-запрос для показа мобильного меню на устройствах с шириной экрана до 768px */
@media screen and (max-width: 768px) {
    .navbar {
        justify-content: right;
    }
    .desktop-menu {
        display: none; /* Скрываем десктопное меню на мобильных устройствах */
    }

    .mobile-menu {
        display: block; /* Показываем мобильное меню на мобильных устройствах */
    }
}

.mobile-menu-trigger {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Выравниваем элементы справа */
    cursor: pointer;
    padding: 10px;
}

.mobile-menu-trigger span {
    display: block;
    height: 3px;
    width: 25px;
    background-color: white;
    margin-bottom: 5px;
    transition: transform 0.3s; /* Добавляем анимацию для перехода */
}

.mobile-menu-trigger.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-trigger.open span:nth-child(2) {
    opacity: 0;
}

.mobile-menu-trigger.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* BurgerMenu.css */

/* Стили для выезжающего меню */
.bm-menu {
    background: #333;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Стили для пунктов меню */
.bm-menu-wrap a {
    color: white;
    margin-bottom: 10px;
}

/* Стили для перекрытия (overlay) */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

/* Стили для кнопки закрытия (креста) */
.bm-cross-button {
    height: 30px;
    width: 30px;
}

/* Стили для холста бургер-меню */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

.main-header-mob a {
    color: black !important;
}

.main-header-mob button {
    margin-right: 20px !important;
}

.btnOrange {
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
    color: #fff !important;
}
