.master_item {
    position: relative;
    display: flex;
    margin: auto;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(61, 61, 61, 0.36);
    border-radius: 5px;
    padding: 15px;
    margin: 0 15px 20px 15px;
}

.master_item img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.ui_element {
    position: absolute;
    width: 15px;
    height: 100%;
    top: 0;
    right: 0;
    background: #fd755a;
    border-radius: 0px 5px 5px 0px;
    z-index: 4;
}

.info_bl {
    margin-left: 10px;
}
.name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.05em;

    color: #000000;
}

.profession {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    letter-spacing: 0.01em;

    color: #2f2f2f;
}

.other {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #5e5e5e;
}

.masters {
    margin-top: 30px;
}
