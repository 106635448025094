.cal {
    padding: 10px;
    font-size: 16px;
}

.cal abbr {
    font-size: 18px;
}

.times_bl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.time_window {
    margin: auto;
    margin: 10px;
}
.time {
    padding: 7px 20px;
    width: 85px;
    background: #ffffff;
    border: 1px solid #ece2c9;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s;
}

.time:hover {
    background-color: #fd755a;
}

.time_active {
    background-color: #fd755a;
    color: #fff;
}

.title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin: 30px 0 20px;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 1px;
}

.phone_bl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.input_phone {
    padding: 10px 10px;
    width: 200px;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 5px;
}
